module.exports = {
  pathPrefix: 'www.sidetrek.com',
  siteTitle: 'Sidetrek | Deviate from the normal day-to-day in order to transform.', // <title>
  manifestName: 'Sidetrek',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  heading: 'Sidetrek',
  subHeading: 'Leadership transformation comes from finding new paths',
  // social
  socialLinks: [
  ],
};
